<template>
  <div class="pageContol listWrap templateList stuExam">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考务管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.go(-1)" class="cur-a">考务列表</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">{{activityId?'`编辑':'新增'}}考务</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
                <el-form ref="form" :model="formData" label-width="96px" size="small" :rules="rules"  label-position="top">
                  <el-row>
                    <el-col :span="11">
                      <el-form-item label="考务名称" prop="activityName" class="form-item">
                        <el-input
                            v-model="formData.activityName"
                            style="width:100%"
                            placeholder="请输入考务名称"
                        />
                      </el-form-item>
                      <el-form-item label="培训工种" prop="occupationId" class="form-item">
                          <el-select
                              clearable
                              v-model="formData.occupationId"
                              filterable
                              remote
                              :remote-method="getOccupation"
                              placeholder="请至少输入两个字搜索"
                          >
                              <el-option
                                  v-for="item in occupation"
                                  :key="item.occupationId"
                                  :label="item.occupationName"
                                  :value="item.occupationId"
                                  >
                              </el-option>
                          </el-select>
                      </el-form-item>
                      <el-form-item label="培训等级"  class="form-item">
                          <el-select
                              v-model="formData.levelCode"
                              placeholder="请选择"
                              clearable
                              filterable
                              size="small"
                              style="width:100%"
                          >
                              <el-option
                                  v-for="item in levelCodeList"
                                  :key="item.value"
                                  :label="item.label"
                                  :value="item.value"
                                  >
                              </el-option>
                          </el-select>
                      </el-form-item>
                      <el-form-item label="考试信息"  class="form-item">
                          <el-button style="float: right;position: absolute;top: -32px;right: 0;" size="small" @click="activityExamAdd" type="primary">新增</el-button>
                      </el-form-item>
                      <div>
                          <div 
                              v-for="(domain, index) in formData.activityExamAddList" :key="index"
                              class="activityItem">
                              <el-form-item 
                                  label="考试分类" 
                                  :prop="'activityExamAddList.' + index + '.subjectCode'" 
                                  :rules="{
                                      required: true, message: '考试分类不能为空', trigger: 'change'
                                  }"
                                  label-width="80px" 
                                  class="form-item">
                                  <el-select
                                      v-model="domain.subjectCode"
                                      placeholder="请选择"
                                      clearable
                                      filterable
                                      size="small"
                                      style="width:100%"
                                  >
                                      <el-option
                                          v-for="item in subjectCodeList"
                                          :key="item.value"
                                          :label="item.label"
                                          :value="item.value"
                                      >
                                      </el-option>
                                  </el-select>
                              </el-form-item>
                              <el-form-item 
                                  label="考试时间" 
                                  :prop="'activityExamAddList.' + index + '.time'" 
                                  :rules="{
                                      required: true, message: '考试时间不能为空', trigger: 'change'
                                  }"
                                  label-width="80px" 
                                  class="form-item">
                                  <el-date-picker
                                      size="small"
                                      v-model="domain.time"
                                      type="datetimerange"
                                      format="yyyy-MM-dd HH:mm:ss"
                                      value-format="yyyy-MM-dd HH:mm:ss"
                                      start-placeholder="开始时间"
                                      end-placeholder="结束时间">
                                  </el-date-picker>
                              </el-form-item>
                              <el-form-item 
                                  label="考试地点" 
                                  :prop="'activityExamAddList.' + index + '.address'" 
                                  :rules="{
                                      required: true, message: '考试地点不能为空', trigger: 'blur'
                                  }"
                                  label-width="80px" 
                                  class="form-item">
                                  <el-input
                                      v-model="domain.address"
                                      style="width:100%"
                                  />
                              </el-form-item>
                              <div style="text-align:right"> 
                                  <el-button size="small" @click="activityExamDel(index)" v-if="formData.activityExamAddList.length>1" type="primary">删除</el-button>
                              </div>
                          </div>
                      </div>
                      <el-form-item 
                          label="证件查询地址" 
                          prop="inquireAddress" 
                          class="form-item">
                          <el-input
                              v-model="formData.inquireAddress"
                              style="width:100%"
                          />
                      </el-form-item>
                    </el-col>
                    <el-col :span="1">&nbsp;</el-col>
                    <el-col :span="12">
                      <el-form-item label="考生须知" prop="notice" class="form-item">
                        <!-- maxlength="500" -->
                        <div ref="editors" id="noticeEditors" style="letter-spacing: 2px !important;width: 100%; position: relative; z-index:10;"/>
                      </el-form-item>
                      <el-form-item label="选择准考证样式" prop="admissionTicketType" class="form-item">
                        <el-radio-group v-model="formData.admissionTicketType">
                          <el-radio :label="item.templateCode" v-for="(item, index) in admissionCertificateList"
                            :key="item.templateCode">
                            {{ item.templateName }}
                            <el-button size="mini" @click="handlePreview(index)" type="text">预览</el-button>
                            <el-image
                              :ref="'preview'+index"
                              style="width: 0;height: 0;"
                              :src="item.certificateImageShow"
                              :preview-src-list="[item.certificateImageShow]"
                            ></el-image>
                          </el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
             <div class="bottom-btn">
              <el-button @click="$router.go(-1)" class="bgc-bv">返回</el-button>
              <el-button
                @click="saveData()"
                class="bgc-bv"
                >保存</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
// 引入富文本
import E from "wangeditor";
import html2canvas from "html2canvas";
import {Loading} from "element-ui";
let editor=null;
export default {
  name: "appraisalInstitution/stuExam",
  components: {
  },
  data() {
    let validateUrl = (rule, value, callback) => {
        const urlregex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\*\+,;=.]+$/;
        if (value && !urlregex.test(value)) {
            callback(new Error("请输入带有http或https的正确链接地址"));
        } else {
            callback();
        }
    };
    return {
      loadingInstance: null,
      noticeKey: null,
      activityId: "",//考务id
      occupation: "",//职业list
      levelCodeList: "",//培训等级list
      subjectCodeList: "",//考试分类list
      formData:{
        activityName: "",//名称
        occupationId: "",//职业Id
        levelCode: "",//培训等级
        notice: "",//考生须知
        inquireAddress: "",//证书查询地址
        admissionTicketType: "",//准考证样式选择
        activityExamAddList:[
            {
                address: "",//考试地址
                time: "",//考试时间
                subjectCode: "",//科目标识
            }
        ]
      },
      rules:{
        activityName:[{required: true, message: '考试名称不能为空', trigger: 'blur'}],
        occupationId:[{required: true, message: '培训工种不能为空', trigger: 'change'}],
        levelCode:[{required: true, message: '培训等级不能为空', trigger: 'change'}],
        notice:[{required: true, message: '考生须知不能为空', trigger: 'blur'}],
        admissionTicketType:[{required: true, message: '准考证样式不能为空', trigger: 'change'}],
        inquireAddress:[{required: false, validator: validateUrl, trigger: 'blur'}],
      },
      admissionCertificateList: [], // 准考证模板
    };
  },
  computed:{
    ...mapGetters({
      userJson: "getUser",
    }),
  },
  mounted() {
    if(this.$route.query.activityId){
        this.activityId = this.$route.query.activityId
        this.getInfo(this.activityId)
    }else{
      this.initE();
      editor.txt.html(
          '1、考生应在考试前30分钟凭准考证和身份证(护照或军官证)入场，“双证”不齐全不得入场，入场后对号入座，并将证件放在桌面左侧。'+'<br/>' +
          '2、开考30分钟内考生不得退场,开考30分钟后迟到考生不得入场。'+'<br/>' +
          '3、考生不得将贵重物品带入考场,考场对考生丢失物品不负任何责任。'+'<br/>' +
          '4、参加上机考试的考生，应在计算机上正确填写个人信息和答案，并承担不正确填写信息的后果。'+'<br/>' +
          '5、参加纸笔作答的考生，只准带钢笔(碳素笔)、2B铅笔、橡皮等必要的文具入座,考生应在试卷、答题卡上正确填写个人信息和答案.并承担不正确使用答题纸(卡)和考试卷的后果。'+'<br/>' +
          '6、遵守考场规则,考试时不准旁窥、交谈、吸烟、传递资料信息或交换座位，严禁作弊。违者视情节轻重将终止其考试或取消考试成绩。'+'<br/>' +
          '7、考试过程中,不得要求监考人员解释试题。'+'<br/>' +
          '8、考试终止时间一到,立即停止答题。'+'<br/>' +
          '9、关闭手机等电子设备并放入手机袋内,随身携带或未关闭手机及电子设备的按违纪处理，取消考试成绩。'+'<br/>' +
          '10、考试结束后仍需妥善保管准考证,以备查询考试成绩信息时使用。'
      );
    }
    this.getCrowdtypeList()
    this.getAdmissionCertificateList()
  },
 
  beforeDestroy(){
    console.log(22222);
      editor.destroy();
      editor=null;
  },
  methods: {
     // 获取准考证模板
    getAdmissionCertificateList() {
      return this.$post("/biz/examination_affairs/activity/selectCertList", {},3000,true,6)
        .then((res) => {
         this.admissionCertificateList = res.data
         if(!this.activityId){
          this.formData.admissionTicketType = res.data[0].templateCode
         }
        })
        .catch(() => {
          return;
        });
    },
    // 预览
    handlePreview(index){
      this.$refs[`preview${index}`][0].clickHandler();
    },
    // 初始化富文本
    initE(){
        if(editor) return;
        // 创建富文本编辑器
        editor = new E(this.$refs.editors);
        editor.config.height = 600
        // 富文本变化赋值
        editor.config.onchange = (value) => {
            this.formData.notice = value;
        };
        // 富文本编辑器的菜单配置
        editor.config.menus = [
            "head", // 标题
            "bold", // 粗体
            "fontSize", // 字号
            "italic", // 斜体
            "underline", // 下划线
            "foreColor", // 文字颜色
            "backColor", // 背景颜色
            "list", // 列表
            "justify", // 对齐方式
            "undo", // 撤销
            "redo", // 重复
        ];
        // 关闭粘贴样式过滤
        editor.config.pasteFilterStyle = false;
        // 忽略粘贴的图片
        editor.config.pasteIgnoreImg = true;
        // 配置粘贴文本的内容处理
        editor.config.pasteTextHandle =  (pasteStr)=> {
            // 对粘贴的文本进行处理，然后返回处理后的结果
            // 如果没有内容不进行处理
            if (pasteStr === "" && !pasteStr) return "";
            // 1.去掉换行符、class
            const stringStripper = /(\n|\r| class=(")?Mso[a-zA-Z]+(")?)/g;
            let output = pasteStr.replace(stringStripper, " ");
            // 2.带Word生成的HTML注释
            const commentSripper = new RegExp("<!--(.*?)-->", "g");
            output = output.replace(commentSripper, "");
            // 3.删除标签，保留内容（如果有）
            const tagStripper = new RegExp("<(/)*(meta|link|span|\\?xml:|st1:|o:|font)(.*?)>","gi");
            output = output.replace(tagStripper, "");
            // 4.删除标签“<style（.）style（.）>”之间的所有内容
            let badTagsReg;
            const badTags = [
                "style",
                "script",
                "applet",
                "embed",
                "noframes",
                "noscript",
            ];
            for (let i = 0; i < badTags.length; i++) {
                badTagsReg = new RegExp("<" + badTags[i] + ".*?" + badTags[i] + "(.*?)>","gi");
                output = output.replace(badTagsReg, "");
            }
            // 5.删除“style=…”的属性
            let attributeStripper;
            const badAttributes = ["style", "start"];
            for (let i = 0; i < badAttributes.length; i++) {
                attributeStripper = new RegExp(" " + badAttributes[i] + '="(.*?)"',"gi");
                output = output.replace(attributeStripper, "");
            }
            return output;
        }
        // 创建富文本编辑器
        editor.create();
    },
    // 考试详情
    getInfo(activityId){
        this.$post("/biz/examination_affairs/activity/getDetails", {activityId},3000,true,6)
        .then((res) => {
          console.log(res);
            let obj = res.data
            // 处理时间
            obj.activityExamAddList = obj.examList.map(e=>{
                let start = this.$moment(e.startTime).format("YYYY-MM-DD HH:mm:ss")
                let end = this.$moment(e.endTime).format("YYYY-MM-DD HH:mm:ss")
                return {
                    address:e.address,
                    activityExamId:e.activityExamId,
                    subjectCode:e.subjectCode,
                    time:[start,end],
                }
            })
            this.occupation = [{occupationName:obj.occupationName,occupationId:obj.occupationId}]
            this.formData = obj
            this.$nextTick(()=>{
                this.initE();
                editor.txt.html(this.formData.notice);
            });
        })
    },
    // 两个字搜索
    getOccupation(occupationName) {
        if (occupationName.trim().length >= 2) {
            this.$post("/biz/examination_affairs/activity/selectCtOccupationList", {occupationName},3000,true,6)
            .then((ret) => {
                this.occupation = ret.data
            })
            .catch((err) => {
                return;
            });
        } else {
            this.occupation = [];
        }
    },
    //码值获取信息
    getCrowdtypeList() {
      const levelCodeList = this.$setDictionary(
        "ET_TRAIN_LEVEL",
        "list"
      );
      const subjectCodeList = this.$setDictionary(
        "EA_EXAM_SUBJECT_CODE",
        "list"
      );
      const list1 = [], list2 = [];
      for (const key in levelCodeList) {
        list1.push({
          value: key,
          label: levelCodeList[key],
        });
      }
      for (const key in subjectCodeList) {
        list2.push({
          value: key,
          label: subjectCodeList[key],
        });
      }

      this.levelCodeList = list1;
      this.subjectCodeList = list2;
    },
    // 新增考试信息
    activityExamAdd(){
        this.formData.activityExamAddList.push({
            address: "",//考试地址
            time: "",//考试时间
            subjectCode: "",//科目标识
        })
    },
    // 删除考试信息
    activityExamDel(index){
        this.formData.activityExamAddList.splice(index,1)
    },
    // 提交
    seveData(){
      this.$refs['form'].validate((valid) => {
          if (valid) {
            let url
            let data = {
                ...this.formData,
                compId:this.userJson.compId,
              noticeKey: this.noticeKey
            }
            if(!this.activityId){
                url = '/biz/examination_affairs/activity/insert'
            }else{
                url = '/biz/examination_affairs/activity/modify' 
                data.activityId = this.activityId
            }
            // 处理时间
            data.activityExamAddList = data.activityExamAddList.map(e=>{
                return {
                    address:e.address,
                    subjectCode:e.subjectCode,
                    startTime:e.time[0],
                    endTime:e.time[1],
                    activityExamId:e.activityExamId??undefined,
                }
            })
            let arr = this.isRepeat(this.formData.activityExamAddList,'subjectCode')
            if(this.formData.notice.length>2000){
              this.$message.error('富文本字数不能大于2000！')
              return
            }
            this.loadingInstance.close()
            if(arr.length>0){
              let text = ''
              arr.forEach(e => {
                text += `【${e[1]}个${this.$setDictionary('EA_EXAM_SUBJECT_CODE',e[0])}考试】`
              });
              this.$confirm(`考试信息中存在${text}是否继续?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.$post(url,data,3000,true,6)
                .then((res) => {
                    this.$message.success('操作成功！')
                    setTimeout(()=>{
                        this.$router.go(-1)
                    },500)
                })
                .catch(() => {
                    return;
                });
              })
            }else{
              this.$post(url,data,3000,true,6)
              .then((res) => {
                  this.$message.success('操作成功！')
                  setTimeout(()=>{
                      this.$router.go(-1)
                  },500)
              })
              .catch(() => {
                  return;
              });
            }
          }
      });
    },
    /**
     * @description: 判断数组中的对象某个值是否相等
     * @param {*} arr 数组
     * @param {*} attribute 属性
     * @return {*} 返回出现大于一次的属性数组
     */    
    isRepeat(arr,attribute) {
      var hash = {};
      for (var i in arr) {
        if(hash[arr[i][attribute]]){
          hash[arr[i][attribute]] += 1
        }else{
          hash[arr[i][attribute]] = 1
        }
      }
      let res = Object.entries(hash)
      res = res.filter(e => e[1]!=1 )
      return res;
    },
    saveData() {

      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.formData.notice.length > 2000) {
            this.$message.error('富文本字数不能大于2000！')
            return
          }
          this.loadingInstance = this.$loading({
            lock: true,
            text: "保存中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          })
          setTimeout(()=>{
            html2canvas(editor.$textElem.elems[0], {scale: 1}).then((res) => {
              res.toBlob(blob => this.handleAvatarSuccess2(blob), "1.0", "image/png");
            });
          },100)
        }
      })
    },
    handleAvatarSuccess2(blob) {
      let formData = new FormData();
      formData.append("folder ", "TEMP");
      formData.append("file ", blob);
      formData.append("fileType ", "png");
      this.$Postformat("/sys/upload", formData)
          .then(result => {
            this.noticeKey = result.data.fileKey;
            this.seveData()
          })
    }
  },
};
</script>
<style lang="less" scoped>
.stuExam{
.el-form {
    width: 100%;
    // padding-left: 160px;
    .form-item {
      width: 100%;
    }
  }
  .activityItem{
    background: #ccc;
    padding: 20px;
    margin-bottom: 20px;
    margin-left: 0;
    border-radius:10px; 
    position: relative;
  }
  .bottom-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .el-textarea {
    .el-textarea__inner {
      resize: none;
    }
  }
}
/deep/.el-form--label-top .el-form-item__label{
  padding: 0;
}
</style>
<style>
#noticeEditors .w-e-text-container {
  height: auto !important;
  min-height: 520px;
  font-family: "SimSun" !important;
}
#noticeEditors .w-e-text {
  height: auto !important;
  min-height: 520px;
  font-family: "SimSun" !important;
}
</style>